






















import {Component, Vue} from "vue-property-decorator";
import {apiUserDeviceDetailById, apiUserDevicePlayList} from "@/api/user/user";

@Component
export default class DeviceDetails extends Vue {
  user_id: string = '';
  // 设备列表
  deviceList = [];


  created() {
    this.getUserDeviceDetail()
  }

//根据用户id查询对应用户设备详情
  async getUserDeviceDetail() {
    const {user_id} = this.$route.query;
    this.deviceList = await apiUserDeviceDetailById(user_id);
  }

// 如果为台历模式则不显示播放列表
  isShow(row: any) {
    return row.play_model != '台历模式';
  }
}
